import React, { useEffect, useState } from 'react';

const calcHeight = (width) => {
    return (width * 9) / 16;
}

const calcWidth = (height) => {
    return (height * 16) / 9;
}

const MessagingV2 = (props) => {
    const getDisplayVars = (props) => {
        // Default
        var screenColumnCount = 1;
        switch (props.screenConfig.columnLayout) {
            case "three-column":
                screenColumnCount = 3;
                break;
            case "two-column":
                screenColumnCount = 2;
                break;
            default:
                screenColumnCount = 1;
                break;
        }
        var sidebarType = props.screenConfig.sidebarType;

        // By default, the columns take up 100% of screen.
        var columnsWidth = 1;
        var widgetMargin = (columnsWidth === 1 && sidebarType === "no-sidebar") ? "3em" : "2em";

        // Sidebars take away some real estate from columns.
        // There can be thin or wide sidebars.
        // Refer to Sidebar.scss for these percentages.
        if (sidebarType === "thin-sidebar") {
            columnsWidth = 0.85;
        } else if (sidebarType === "wide-sidebar") {
            columnsWidth = 0.75;
        }

        // Initial decision
        var width = (window.innerWidth * columnsWidth) / screenColumnCount;
        var height = calcHeight(width);

        return {width: width, height: height, screenColumnCount: screenColumnCount, margin: widgetMargin};
    }

    const [ displayVars, setDisplayVars ] = useState(getDisplayVars(props));
    // const [ dimensions, setDimensions ] = useState(getDisplayVars(props));

    let resizeWidget = () => {
        setDisplayVars(getDisplayVars(props));
    }

    const divStyling = () => {
        // Basic styling
        var styling = {
            position: "relative",
            display: "block",
            margin: "0 1em 1.5em 1em",
            height: "auto",
            width: `calc(${displayVars.width}px - ${displayVars.margin}em)`,
            aspectRatio: "16/9"
        };

        return styling;
    };

    const getBlockContents = () => {
        if (props.screenConfig?.device?.hash) {
            return <iframe width="100%" height="100%" src={process.env.REACT_APP_LOADER_URL + props.screenConfig.device.hash + "?loaderContext=screen-pro-messaging"} frameBorder="0" allowFullScreen="" style={{width: "100%", height: "100%",position: "absolute", top: 0, left: 0}}>
          </iframe>
        } else {
            return <div>Missing device info</div>
        }
    }

    useEffect(() => {
        resizeWidget();
        window.addEventListener('resize', resizeWidget);
        return () => {
          window.removeEventListener('resize', resizeWidget);
        }
    }, []);

    return (
        <div style={divStyling()}>
            { getBlockContents() }
        </div>
    )
}

MessagingV2.displayName = "MessagingV2";
export default MessagingV2;
